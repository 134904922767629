import { graphql, Link } from "gatsby"
import React from "react"
import { Container } from "reactstrap"
import Layout from "../components/layout"
import Search from "../components/search/knowledgeSearch"
import Seo from "../components/seo"

import "./knoweledgebasetemplate.css"
// const slugify = require("slugify")

import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const LeftPanel = loadable(() =>
  pMinDelay(import("../components/panel/leftpanel"), 500)
)

const Banner = ({ date, title, category, posts }) => (
  <>
    <div className="hero-banner hero-knoweledgebase">
      <div className="container">
        <div className="hero-banner-content text-center">
          <h1 className="page-title font-weight-bold text-white mt-2">
            Knowledge Base
          </h1>
          <Search posts={posts} />
        </div>
      </div>
    </div>
  </>
)

class KnowledgeBaseCategory extends React.Component {
  render() {
    const { post, categoryBase, allPosts } = this.props.data
    // const { previous, next } = this.props.pageContext
    const posts = categoryBase.edges
    const allpost = allPosts.edges
    const breadcrumbs = posts.filter(({ node }) => node.title === post.title)

    return (
      <Layout
        location={this.props.location}
        banner={<Banner posts={allpost} />}
      >
        <Seo title={`${post.title} Archives - Quandary Consulting Group`} />
        <div className="breadcrumbs">
          <div className="container">
            <ul className="list-unstyled">
              <li>
                <Link aria-label="link" to="/knowledge-base/">
                  KnowledgeBase
                </Link>
              </li>
              {breadcrumbs.map((items, index) => {
                const categorytitle = post.categories
                  ? post.categories[0].title
                  : ""
                const subcategories = items.node.subCategory.filter(
                  item => item.title === categorytitle
                )
                const currentPage = items.node.knowledgebasepage.filter(
                  item => item.title === post.title
                )
                return (
                  <>
                    <li>
                      <Link
                        aria-label="link"
                        to={`/knowledge-base/${items.node.slug.current}/`}
                      >
                        {items.node.title}
                      </Link>
                    </li>
                    {subcategories ? (
                      <>
                        {subcategories.map((item, index) => {
                          const subPage = item.knowledgebasepage.filter(
                            item => item.title === post.title
                          )
                          return (
                            <>
                              <li>{item.title}</li>
                              {subPage.map((item, index) => {
                                return <li>{item.title}</li>
                              })}
                            </>
                          )
                        })}
                      </>
                    ) : (
                      ""
                    )}
                    {currentPage ? (
                      <>
                        {currentPage.map((item, index) => {
                          return <li>{item.title}</li>
                        })}
                      </>
                    ) : (
                      <li>{post.title}</li>
                    )}
                  </>
                )
              })}
            </ul>
          </div>
        </div>
        <div className="page-content knowledge-category-page">
          <Container>
            <div className="knowledge-single-heading my-4">
              <span
                aria-hidden="true"
                className={`knowledge-single-heading-icon text-light-blue mt-1 mr-2 ${post.icon}`}
              />
              <h1 className="mt-0 font-weight-bold">{post.title}</h1>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="row justify-content-between align-items-stretch h-100">
                  {post.subCategory.map((item, idx) => {
                    let page = item.knowledgebasepage
                    return (
                      <div key={item.id} className="col-md-6 mb-4">
                        <div className="knowledge-section h-100">
                          <div className="knowledge-heading">
                            <span
                              aria-hidden="true"
                              className={`knowledge-heading-icon colored fa fa-folder-o`}
                            />
                            <p className="h5 font-weight-bold mt-0">
                              {" "}
                              <Link
                                to={`/knowledge-base/${item.slug.current}/`}
                              >
                                {item.title}
                              </Link>
                            </p>
                          </div>
                          <span className="knowledge-post-count">
                            {page.length}
                          </span>
                          <ul className="knowledge-section-list list-unstyled m-0">
                            {page.slice(0, 6).map((pages, i) => {
                              return (
                                <li
                                  key={pages.id}
                                  className="knowledge-post-link"
                                >
                                  <div className="knowledge-subheading">
                                    <span
                                      aria-hidden="true"
                                      className="knowledge-heading-icon fa fa-file-text-o mt-1 mr-2"
                                    />
                                    <Link
                                      to={`/knowledge-base/${item.slug.current}/${pages.slug.current}/`}
                                    >
                                      {pages.title}
                                    </Link>
                                  </div>
                                </li>
                              )
                            })}
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <Link
                              to={`/knowledge-base/${item.slug.current}/`}
                              className="knowledge-viewall"
                            >
                              View all {page.length} articles
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                  <div className="col-12 mb-4">
                    <div className="knowledge-without-sub-section">
                      <ul className="knowledge-section-list list-unstyled m-0">
                        {post.knowledgebasepage.map((item, idx) => {
                          return (
                            <li key={item.id} className="knowledge-post-link">
                              <div className="knowledge-subheading">
                                <span
                                  aria-hidden="true"
                                  className="knowledge-heading-icon fa fa-file-text-o mt-1 mr-2"
                                />
                                <Link
                                  className="font-weight-medium"
                                  to={`/knowledge-base/${post.slug.current}/${item.slug.current}/`}
                                >
                                  {item.title}
                                </Link>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <LeftPanel posts={posts} />
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default KnowledgeBaseCategory

export const knowledgeBaseCategoryQuery = graphql`
  query knowledgeBaseCategoryQueryTemplateQuery($slug: String!) {
    post: sanityKnowledgebasecategory(slug: { current: { eq: $slug } }) {
      id
      title
      icon
      slug {
        current
      }
      subCategory {
        id
        title
        slug {
          current
        }
        knowledgebasepage {
          id
          title
          slug {
            current
          }
        }
      }
      knowledgebasepage {
        id
        title
        slug {
          current
        }
      }
    }
    categoryBase: allSanityKnowledgebasecategory(
      sort: { fields: [order, knowledgebasepage___publishedAt], order: ASC }
    ) {
      edges {
        node {
          id
          title
          icon
          slug {
            current
          }
          subCategory {
            id
            title
            slug {
              current
            }
            knowledgebasepage {
              id
              title
              slug {
                current
              }
            }
          }
          knowledgebasepage {
            id
            title
            publishedAt
            slug {
              current
            }
          }
        }
      }
    }
    allPosts: allSanityKnowledgeBase {
      edges {
        node {
          id
          title
          metaDescription
          body
          slug {
            current
          }
          categories {
            ... on SanitySubCategory {
              id
              title
              slug {
                current
              }
            }
            ... on SanityKnowledgebasecategory {
              id
              title
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`
